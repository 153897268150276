import React from "react";

import { ethers } from "ethers";
import { isInWeb3Browser, connect, switchNetwork, ABI_ERC721, ABI_APP1, onAccountChange } from "../../common/dapp.js";
import { CHAIN_EXPLORER, CHAIN_ID, CHAIN_NAME, CHAIN_SYMBOL, EGGX_APP1_CONTRACT, EGGX_CONTRACT, RPC } from "../../common/constant.js";
import { Badge, Button, Card, FormControl } from "react-bootstrap";
import Header from "../../components/common/Header.js";
import { useTranslation } from "react-i18next";

import '../../i18n.js'
import copy from 'copy-to-clipboard';
import { t } from "i18next";
import { postJson } from "../../common/rest.js";

class _App11Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            provider: null,
            address: null,
            balance: null,
            isInWeb3: false,
            chainId: null,
            days: 3,
            reward: 'Loading...',
            showInfo: false,
            loading: false,

            valid: false,
            showError: false,
            message: '',

            showRule: false,

            totalReleased: '-',
            totalSupply: '-',
            rewardPerOption: '-',

            showSuccessInfo: false,
            txHash: null,
            showMaintenance: false
        }
        this.slots = []
        for (let i = 0; i < 6; i++) {
            this.slots[i] = React.createRef()
        }
    }

    async componentDidMount() {
        this.onLoad();
    }

    onLoad = async () => {
        const isInWeb3 = isInWeb3Browser();
        this.setState({ isInWeb3 });
        if (isInWeb3) {
            const options = await connect();
            this.setState({
                provider: options.provider,
                address: options.address,
                chainId: options.chainId,
            });

            onAccountChange({
                success: (accounts) => {
                    this.setState({
                        address: accounts[0]
                    });
                }
            });

            if (options.chainId != CHAIN_ID) {
                if (window.confirm("You are on the wrong network, do you want to switch to " + CHAIN_NAME + "?")) {
                    switchNetwork({
                        chainId: CHAIN_ID,
                        chainname: CHAIN_NAME,
                        symbol: CHAIN_SYMBOL,
                        decimals: 18,
                        rpcUrls: [RPC],
                        explorer: CHAIN_EXPLORER
                    }, () => {
                        this.onLoad();
                    });
                }
            } else {
                // 
                this.initData(options)
            }
        }
    }

    initData = async (options) => {
        const appContract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI_APP1, options.provider);
        const that = this
        appContract.totalRelease().then((res) => {
            this.setState({
                totalReleased: that.formatNumber(ethers.formatUnits(res, 18) - 0)
            });
        })
        appContract.lockDays().then((res) => {
            this.setState({
                days: res
            });
        })
        // totalSupply
        appContract.totalSupply().then((res) => {
            this.setState({
                totalSupply: that.formatNumber(ethers.formatUnits(res, 18) - 0)
            });
        })
        // reward
        appContract.getReward().then((res) => {
            this.setState({
                rewardPerOption: that.formatNumber(ethers.formatUnits(res, 18) - 0)
            });
        })

    }

    shortAddress = (address) => {
        if (address) {
            return address.substr(0, 8) + '...' + address.substr(-6);
        }
    }
    onConfirm = async (nft) => {
        this.setState({
            loading: true
        });
        // const { nftId, meta, index } = nft;
        // 第一个nft
        const nft0 = this.slots[0].current.state;
        console.log(nft0);

        if (!nft0.meta) {
            this.setState({
                loading: false
            });
            return;
        }

        const exists = [nft0.nftId];
        const existsColor = [nft0.meta.newAttributes.Color];

        if (nft0.meta) {
            // 检查其它所有有nft的卡槽
            for (let i = 1; i < 6; i++) {
                const nft = this.slots[i].current.state;
                if (nft.meta) {

                    if (nft.meta.newAttributes.Pattern != nft0.meta.newAttributes.Pattern) {
                        console.log("pattern = " + nft.meta.newAttributes.Pattern + " != " + nft0.meta.newAttributes.Pattern)
                        // this.slots[i].current.setError('ID ' + nft.nftId + ' 与第一个NFT不是同一种花纹');
                        this.slots[i].current.setErrorField('Pattern');
                        continue;
                    } else {
                        this.slots[i].current.setSuccess();
                    }

                    if (exists.includes(nft.nftId)) {
                        // this.slots[i].current.setError('ID ' + nft.nftId + ' 重复了');
                        this.slots[i].current.setErrorField('ID');
                        continue;
                    } else {
                        this.slots[i].current.setSuccess();
                    }
                    if (existsColor.includes(nft.meta.newAttributes.Color)) {
                        // this.slots[i].current.setError('ID ' + nft.nftId + ' 与已有的颜色重复了');
                        this.slots[i].current.setErrorField('Color');
                        continue;
                    } else {
                        this.slots[i].current.setSuccess();
                    }

                    existsColor.push(nft.meta.newAttributes.Color);
                    exists.push(nft.nftId);
                }
            }
        }

        this.setState({
            loading: false
        });
    }

    //是否所有NFT都已经添加
    isAllNftAdded = () => {
        for (let i = 0; i < 6; i++) {
            const nft = this.slots[i].current.state;
            if (!nft.meta) {
                return false;
            }
        }
        return true;
    }

    formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    // 获取所有的NFT，如果是空的，那对应位置是null
    getAllNfts = async () => {
        // 验证
        const nfts = [];
        for (let i = 0; i < 6; i++) {
            const { nft } = this.slots[i].current.state;
            nfts[i] = nft;
        }
        console.log(nfts);
    }

    submit = async () => {
        // 验证并领取
        // 所有的nft slot都满了
        // loading = true


        const { t } = this.props;
        for (let i = 0; i < 6; i++) {
            const nft = this.slots[i].current.state;
            if (!nft.meta) {
                // this.alert('请在第' + (i + 1) + '卡槽放入NFT ID');
                this.alert(t('Alert1', { idx: i + 1 }));
                return;
            }
        }
        // 所有的nft都和第一个nft是同一种花纹
        for (let i = 1; i < 6; i++) {
            const nft = this.slots[i].current.state;
            if (nft.meta.newAttributes.Pattern != this.slots[0].current.state.meta.newAttributes.Pattern) {
                // this.alert('第' + (i + 1) + '个NFT与第一个NFT不是同一种花纹');
                this.alert(t('Alert2', { idx: i + 1 }));
                return;
            }
        }
        // 所有的nft的颜色都没有重复
        const exists = [this.slots[0].current.state.nftId];
        const existsColor = [this.slots[0].current.state.meta.newAttributes.Color];
        for (let i = 1; i < 6; i++) {
            const nft = this.slots[i].current.state;
            if (exists.includes(nft.nftId)) {
                // this.alert('ID ' + nft.nftId + ' 重复了');
                this.alert(t('Alert3', { id: nft.nftId }));
                return;
            }
            if (existsColor.includes(nft.meta.newAttributes.Color)) {
                // this.alert('ID ' + nft.nftId + ' 与已有的颜色重复了');
                this.alert(t('Alert4', { id: nft.nftId }));
                return;
            }
            existsColor.push(nft.meta.newAttributes.Color);
            exists.push(nft.nftId);
        }

        // 所有的nft在最近3天都没有用过
        const options = await connect();
        const signer = await options.provider.getSigner();
        const appContract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI_APP1, signer);

        for (let i = 0; i < 6; i++) {
            const nft = this.slots[i].current.state;
            const used = await appContract.isTokenIdUsed(nft.nftId);
            if (used) {
                // this.alert('ID ' + nft.nftId + ' 在最近3天内已经使用过了');
                this.alert(t('Alert5', { id: nft.nftId, days: this.state.days }));
                return;
            }
        }
        try {        // 向合约发起提交
            this.setState({
                loading: true
            });
            const res = await postJson('https://eggx-app1-worker.bdd-account.workers.dev/getSignature', {
                'address': ethers.getAddress(options.address),
                tokenIds: [
                    parseInt(this.slots[0].current.state.nftId),
                    parseInt(this.slots[1].current.state.nftId),
                    parseInt(this.slots[2].current.state.nftId),
                    parseInt(this.slots[3].current.state.nftId),
                    parseInt(this.slots[4].current.state.nftId),
                    parseInt(this.slots[5].current.state.nftId),
                ]
            });
            // 'function transfer(bytes memory signature,address to,uint256 amount,uint256 deadline,uint256[] memory tokenIds) external',
            console.log(res);
            const tx = await appContract.transfer(res.signature, res.to, res.amount, res.deadline, res.tokenIds);
            console.log(tx);

            this.setState({
                loading: false
            });
            const that = this;
            this.setState({
                txHash: tx.hash,
                showSuccessInfo: true,
                rewardPerOption: that.formatNumber(ethers.formatUnits(res.amount, 18) - 0)
            });

        } catch (e) {
            console.error(e);
            this.setState({
                loading: false
            });
            this.alert(t('SubmitFail') + '  ' + (e.reason || e.message || e));
        } finally {
            this.setState({
                loading: false
            });
        }

    }

    alert = (msg) => {
        this.setState({
            showError: true,
            message: msg
        });
    }

    render() {
        const {
            chainId,
            address,
            reward,
            days,
            showInfo,

            totalReleased,
            totalSupply,
            rewardPerOption,

        } = this.state;

        const { t } = this.props;

        return (
            <div style={{
                height: "100%",
                minHeight: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}>
                <div style={{
                    maxWidth: "600px",
                }}>
                    {/* 钱包连接区 */}
                    <Header title={t('AppTitle')} address={address} chainId={chainId} onClick={this.onLoad} onShowRule={(e) => {
                        this.setState({
                            showRule: true
                        })
                    }} />
                    <div style={{
                        padding: "1rem"
                    }}>
                        <Card>
                            <Card.Body>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    textAlign: "center",
                                }}>
                                    <div style={{
                                        width: "33%",
                                    }}>
                                        <div style={{
                                            fontSize: "1.2rem",
                                            fontWeight: "bold",
                                            color: "white",
                                        }}>{totalSupply}</div>
                                        <div style={{
                                            fontSize: "0.8rem",
                                            marginTop: "0.5rem",
                                        }}>{t('TotalReward')}</div>
                                    </div>
                                    <div style={{
                                        width: "33%",
                                    }} >
                                        <div style={{
                                            fontSize: "1.2rem",
                                            fontWeight: "bold",
                                            color: "white",
                                        }}>
                                            {/* {totalReleased} */}
                                            {this.state.showMaintenance ? '维护中/In maintenance' : totalReleased}
                                        </div>
                                        <div style={{
                                            fontSize: "0.8rem",
                                            marginTop: "0.5rem",
                                        }}>
                                            {t('RewardIssued')}
                                        </div>
                                    </div>
                                    <div style={{
                                        width: "33%",
                                    }}>
                                        <div style={{
                                            fontSize: "1.2rem",
                                            fontWeight: "bold",
                                            color: "white",
                                        }}>{rewardPerOption}</div>
                                        <div style={{
                                            fontSize: "0.8rem",
                                            marginTop: "0.5rem",
                                        }}>
                                            {t('EveryReward')}
                                        </div>
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                        {/* 说明区 */}
                        <div>

                            {/* 卡槽选择区 */}
                            <div style={{
                                marginTop: "1.5rem",
                                marginBottom: "1.5rem"
                            }}>
                                <div>{t('Note1')}   </div>
                                <div>{t('Note2')}  </div>
                                <div>{t('Note3')}  </div>
                            </div>
                            <div>
                                {/* 遍历0-6，显示6个<CardSlot onConfirm={(e) => {

                        }} /> */}
                                {[0, 1, 2, 3, 4, 5].map((i) => {
                                    return <CardSlot days={days} ref={this.slots[i]} key={i} onConfirm={(nft) => {
                                        console.log(nft);
                                        this.onConfirm(nft);
                                    }} index={i} t={t} />
                                })}
                            </div>
                            {/* 验证并领取 */}
                            <div>
                                <div className="d-grid gap-2">
                                    {/* 背景是从#86EFEF到#A9FB7D过渡色 */}
                                    <Button style={{
                                        backgroundImage: "linear-gradient(45deg, #86EFEF, #A9FB7D)",
                                        color: "black",
                                        border: "none",
                                        height: "3.6rem",
                                    }} variant="primary" size="lg" onClick={(e) => {
                                        this.submit();
                                    }}>
                                        {t('CheckButton')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* end of showInfo? */}

                        {/* loading cover */}
                        <div style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: this.state.loading ? "flex" : "none",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: "999",
                        }}>
                            <div style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                            }}>
                                {/* <img src={require('../../assets/icons/loading.gif')} style={{
                            width: "4rem",
                            height: "4rem",
                        }} /> */}

                                Loading...
                            </div>
                        </div>
                    </div>
                    {this.state.showRule ? <div style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        backdropFilter: "blur(3px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "999",

                    }} onClick={(e) => {
                    }}>
                        <div style={{
                            width: "80%",
                            backgroundColor: "white",
                            padding: "1rem",
                            borderRadius: "1.5rem",
                            position: "relative",
                            color: "black",

                        }}>
                            <center style={{ marginTop: '1rem', marginBottom: '1.2rem' }}><h4>{t('RuleTitle')}</h4></center>
                            <p>{t('Rule1')} </p>
                            <p>{t('Rule2')} </p>
                            <p>{t('Rule3')} </p>
                            <p>{t('Rule4')} </p>
                            <p>{t('Rule5')} </p>
                            <p>{t('Rule6')} </p>
                            <p>{t('Rule7')} </p>
                            <p>{t('Rule8', { days: days })} </p>

                            <div style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                            }} onClick={(e) => {
                                this.setState({
                                    showRule: false
                                });

                            }}>
                                OK
                            </div>
                        </div>
                    </div> : null}

                    {/* show error */}
                    {this.state.showError ? <div style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "999",
                    }} onClick={(e) => {
                        this.setState({
                            showError: false
                        });

                    }}>
                        <div style={{
                            width: "80%",
                            backgroundColor: "white",
                            padding: "1rem",
                            borderRadius: "1.5rem",
                            position: "relative",
                        }}>
                            <div style={{
                                textAlign: "center"
                                , marginBottom: "2rem"
                                , marginTop: "2rem"
                            }}>
                                <img src={require('../../assets/icons/error2.png')} style={{
                                    width: "3rem",
                                    height: "3rem",
                                }} onClick={(e) => {
                                    this.setState({
                                        showError: false
                                    });
                                }} />
                            </div>
                            <div style={{
                                textAlign: "center",
                                color: "black",
                                marginBottom: "2rem"
                            }}>
                                {this.state.message}
                            </div>
                            <div>
                                <center style={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    color: "black",
                                }} onClick={(e) => {
                                    this.setState({
                                        showError: false
                                    });

                                }}>
                                    OK
                                </center>
                            </div>
                        </div>
                    </div> : null}

                    {/* show success info */}

                    {this.state.showSuccessInfo ? <div style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "999",
                    }} onClick={(e) => {
                        this.setState({
                            showSuccessInfo: false
                        });

                    }}>
                        <div style={{
                            width: "80%",
                            backgroundColor: "white",
                            padding: "1rem",
                            borderRadius: "1.5rem",
                            position: "relative",
                        }}>
                            <div style={{
                                textAlign: "center"
                                , marginBottom: "2rem"
                                , marginTop: "2rem"
                            }}>
                                <img src={require('../../assets/icons/waiting.png')} style={{
                                    width: "3rem",
                                    height: "3rem",
                                }} onClick={(e) => {
                                    this.setState({
                                        showSuccessInfo: false
                                    });
                                }} />
                            </div>
                            <div style={{
                                textAlign: "center",
                                color: "black",
                                marginBottom: "2rem"
                            }}>
                                <div>{t('ResultMessage1')} {rewardPerOption} EGGX</div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}>
                                    Hash: {this.shortAddress(this.state.txHash)}
                                    <img src={require('../../assets/icons/copy.png')} style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        marginLeft: "0.5rem",
                                    }} onClick={(e) => {
                                        copy(this.state.txHash);
                                    }} />
                                </div>
                                <span style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                }} onClick={(e) => {
                                    document.location.href = CHAIN_EXPLORER + '/tx/' + this.state.txHash;
                                }}>{t('ResultMessage2')}</span>
                            </div>
                            <div>
                                <center style={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    color: "black",
                                }} onClick={(e) => {
                                    this.setState({
                                        showSuccessInfo: false
                                    });
                                    // reload
                                    document.location.reload();
                                }}>
                                    OK
                                </center>
                            </div>
                        </div>
                    </div> : null}

                </div>
                {/* mask */}
                {this.state.showMaintenance ? <div style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "999",
                }} onClick={(e) => {

                }}>
                    <div style={{
                        width: "80%",
                        maxWidth: "300px",
                        backgroundColor: "white",
                        padding: "1rem",
                        borderRadius: "1.5rem",
                        position: "relative",
                    }}>
                        <div style={{
                            textAlign: "center"
                            , marginBottom: "2rem"
                            , marginTop: "2rem"
                            , color: 'black'
                        }}>
                            维护中 / In maintenance
                        </div>
                    </div>
                </div> : null}
            </div >
        );
    }
}

class CardSlot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nftId: null,
            meta: null,
            showInput: false,
            inputed: false,
            valid: false,
            message: '',
            field: '',
        }
    }

    onConfirm = async () => {
        const { nftId } = this.state;
        const { t } = this.props;
        if (nftId) {
            // 验证nftId是否合法
            try {
                const options = await connect();

                // nft所有者对不对?
                const erc721 = new ethers.Contract(EGGX_CONTRACT, ABI_ERC721, options.provider);
                const owner = await erc721.ownerOf(nftId);
                console.log(owner);

                if (!owner) {
                    this.setState({
                        valid: false,
                        message: t('BoardError1')
                    });
                    return;
                }

                if (owner.toLowerCase() != options.address.toLowerCase()) {
                    this.setState({
                        valid: false,
                        // message: 'NFT ID ' + this.state.nftId + ' 不属于当前钱包地址'
                        message: t('BoardError2', { id: this.state.nftId })
                    });
                    return;
                }

                // 检查token id是否用过
                const eggx = new ethers.Contract(EGGX_APP1_CONTRACT, ABI_APP1, options.provider);
                // const used = await eggx.isTokenIdUsed(nftId);

                const usedTime = await eggx.usedToken(nftId);
                console.log('last time', usedTime);

                // if (used === true) {
                // this.setState({
                // valid: false,
                // message: 'NFT ID ' + this.state.nftId + ' 已经使用过了，请在规定时间后再次使用'
                // message: t('BoardError3', { id: this.state.nftId })
                // });
                // return;
                // }

                if (usedTime > 0) {
                    let u = parseInt(usedTime);
                    // 最后使用时间  *1000 + 3天的毫数
                    const nextUseTime = parseFloat(u * 1000 + (parseInt(this.props.days)) * 24 * 60 * 60 * 1000);
                    const now = new Date().getTime();
                    const diff = nextUseTime - now;
                    const days = diff / (24 * 60 * 60 * 1000);
                    const hours = (diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000);
                    const minutes = (diff % (60 * 60 * 1000)) / (60 * 1000);


                    this.setState({
                        valid: false,
                        message: t('BoardError6', {
                            id: this.state.nftId,
                            days: Math.floor(days),
                            hours: Math.floor(hours),
                            minutes: Math.floor(minutes),
                            tDays: this.props.days
                        })
                    });
                    return;
                }

                const meta = await erc721.tokenURI(this.state.nftId);
                const jsonMeta = meta.split('data:application/json;utf8,')[1];
                const metaObj = JSON.parse(jsonMeta);

                // 把metaObj里的attributes转换成metaObj的属性
                const attributes = metaObj.attributes;
                const newAttributes = {};
                for (let i = 0; i < attributes.length; i++) {
                    const attr = attributes[i];
                    newAttributes[attr.trait_type] = attr.value;
                }
                metaObj.newAttributes = newAttributes;

                this.setState({
                    meta: metaObj,
                    valid: true,
                    message: '',
                    inputed: true,
                });

                setTimeout(() => {
                    this.setState({
                        showInput: false
                    });
                    if (typeof this.props.onConfirm === 'function') {
                        this.props.onConfirm({
                            nftId: this.state.nftId,
                            meta: metaObj,
                            index: this.props.index,
                        });
                    }
                }, 600);



            } catch (e) {
                console.error(e);
                this.setState({
                    valid: false,
                    // message: t('BoardError4', { id: this.state.nftId }) + (e.message || e.reason || e)
                    message: t('Alert6', { id: this.state.nftId })
                });
            }
        } else {
            this.setState({
                inputed: true,
                valid: false,
                message: t('BoardError5')
            });
        }
    }

    setError = (message) => {
        this.setState({
            valid: false,
            message: message
        });
    }

    setErrorField = (field) => {
        this.setState({
            valid: false,
            field: field
        });
    }

    setSuccess = () => {
        this.setState({
            valid: true,
            message: '',
            field: ''
        });
    }

    _t = (src) => {
        const tr = {
            "Color": "颜色",
            "Pattern": "花纹",
            "Wings": "翅膀",

            "red": "红色",
            "blue": "蓝色",
            "green": "绿色",
            "yellow": "黄色",
            "black": "黑色",
            "camouflage": "迷彩",

            "nebula": "星云",
            "star": "星星",
            "cloud": "云朵",
            "mountain": "山脉",
            "river": "河流",
            "flower": "花朵",
            "scale": "鳞片",

            "winged": "有翅膀",
            "wingless": "无翅膀"
        }
        return tr[src] || src;
    }

    render() {
        const { address } = this.props;
        const { meta, valid, message, field } = this.state;
        // 红光报错
        const styleErrorBorder = {
            boxShadow: "0 0px 10px rgba(255, 0, 0, 0.6)",
            marginBottom: "1.5rem",
            borderRadius: "0.5rem",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
        }

        // 绿光正确
        const styleSuccessBorder = {
            boxShadow: "0 0px 10px rgba(0, 255, 0, 0.6)",
            marginBottom: "1.5rem",
            borderRadius: "0.5rem",
            backgroundColor: "rgba(0, 255, 0, 0.1)",
        }
        // 空白
        const styleEmptyBorder = {
            boxShadow: "unset",
            marginBottom: "1.5rem",
            borderRadius: "0.5rem",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
        }
        // random
        const i = Math.random();
        // const backgroundColor = valid && meta ? "rgba(0, 255, 0, 0.1)" : valid && !meta ? "rgba(0, 0, 0, 0.1)" : !valid && meta ? "rgba(255, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.1)";
        // const css = valid && meta ? styleSuccessBorder : valid && !meta ? styleEmptyBorder : !valid && meta ? styleErrorBorder : styleEmptyBorder;

        const status = valid && meta ? 1 : valid && !meta ? 0 : !valid && meta ? 2 : 0;

        const css = styleEmptyBorder;
        return (
            <div>
                <div style={styleEmptyBorder}>
                    <Card style={
                        css
                        // backgroundColor: meta ? "rgba(0, 255, 0, 0.1)" : "rgba(0, 0, 0, 0.1)",
                        // backgroundColor: backgroundColor,
                    } onClick={(e) => {
                        this.setState({
                            showInput: true
                        });
                    }}>
                        <Card.Body>
                            {meta ? <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "4rem",
                            }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}>
                                    <img src={meta.image} style={{
                                        height: "3rem",
                                        borderRadius: "0.5rem",
                                        marginRight: "1rem",
                                    }} />
                                    <div>
                                        <div style={{
                                            fontSize: "1.2rem",
                                            fontWeight: "bold",
                                            marginBottom: "0.5rem",
                                        }}>{meta.name}</div>
                                        {/* for meta.attributes */}
                                        <div style={{
                                            fontSize: "0.8rem",
                                            display: "flex",
                                        }}>

                                            <div style={{
                                                marginRight: "0.5rem",
                                                backgroundColor: 'Pattern' === field ? "red" : "#121212",
                                                paddingTop: "0.4rem",
                                                paddingBottom: "0.4rem",
                                                paddingLeft: "0.6rem",
                                                paddingRight: "0.6rem",
                                                borderRadius: "1.5rem",
                                            }} key={1}> {meta.newAttributes.Pattern}</div>

                                            <div style={{
                                                marginRight: "0.5rem",
                                                backgroundColor: field === 'Color' ? "red" : "#121212",
                                                paddingTop: "0.4rem",
                                                paddingBottom: "0.4rem",
                                                paddingLeft: "0.6rem",
                                                paddingRight: "0.6rem",
                                                borderRadius: "1.5rem",
                                            }} key={0}> {meta.newAttributes.Color}</div>



                                            <div style={{
                                                marginRight: "0.5rem",
                                                backgroundColor: 'Wings' === field ? "red" : "#121212",
                                                paddingTop: "0.4rem",
                                                paddingBottom: "0.4rem",
                                                paddingLeft: "0.6rem",
                                                paddingRight: "0.6rem",
                                                borderRadius: "1.5rem",
                                            }} key={2}> {meta.newAttributes.Wings}</div>

                                            {/* {meta.attributes.map((attr, i) => {
                                                return <div style={{
                                                    marginRight: "0.5rem",
                                                    backgroundColor: attr.trait_type === field ? "red" : "#121212",
                                                    paddingTop: "0.4rem",
                                                    paddingBottom: "0.4rem",
                                                    paddingLeft: "0.6rem",
                                                    paddingRight: "0.6rem",
                                                    borderRadius: "1.5rem",
                                                }} key={i}> {attr.value}</div>
                                            })} */}
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    width: "4rem",
                                    fontSize: "10pt",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "4rem",
                                        color: "red",
                                    }}>
                                        {/* {message} */}
                                        {status === 1 ? <div>

                                        </div> : status === 2 ? <div>
                                            <img src={require('../../assets/icons/error1.png')} style={{
                                                width: "2rem",
                                                height: "2rem",
                                            }} />
                                        </div> : null}
                                    </div>
                                </div>

                            </div> : <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "2rem",
                            }}>
                                <img src={require('../../assets/icons/plus1.png')} style={{
                                    width: "1.2rem",
                                    height: "1.2rem",
                                }} />
                            </div>}
                        </Card.Body>
                    </Card>
                </div>
                {/* 弹出层，输入token id */}
                <div style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    // 高斯模糊滤镜
                    backdropFilter: "blur(3px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "999",
                    display: this.state.showInput ? "flex" : "none",
                }}>

                    <div style={{
                        width: "80%",
                        backgroundColor: "white",
                        padding: "1rem",
                        borderRadius: "1.5rem",
                        position: "relative",
                    }} data-bs-theme="light">
                        {/* <div>
                            <img src={require('../../assets/icons/close.png')} style={{
                                width: "2.2rem",
                                height: "2.2rem",
                                position: "absolute",
                                top: "0",
                                right: "0",
                            }} onClick={(e) => {
                                this.setState({
                                    showInput: false
                                });
                            }} />
                        </div> */}

                        <div style={{
                            marginBottom: "2rem",
                            marginTop: "1rem",
                        }} >
                            {/* <div style={{
                                marginBottom: "1rem",
                                backgroundColor: "rgba(0, 0, 0, 0.1)",
                                padding: "1rem",
                                borderRadius: "0.5rem",
                            }}>
                                请输入您所拥有的NFT ID，您可以在<a target="_blank" href={CHAIN_EXPLORER + '/nft-transfer?a=' + address}>区块链浏览器</a>中查看自己地址的EGGX NFT，记录6个相同花纹、不同颜色的NFT ID。如果6个NFT<b>都有翅膀</b>，您可以获得更多奖励。
                            </div> */}
                            <FormControl
                                placeholder={t('BoardError5')}
                                aria-label={t('BoardError5')}
                                aria-describedby="basic-addon2"
                                value={this.state.nftId ?? ''}
                                size="lg"
                                isInvalid={this.state.inputed && !this.state.valid}
                                isValid={this.state.inputed && this.state.valid}
                                type="number"
                                onChange={(e) => {
                                    this.setState({
                                        nftId: e.target.value
                                    });
                                }}
                            />
                            {/* error message */}
                            <div style={{
                                wordBreak: "break-all",
                                marginTop: "0.5rem",
                                color: "red",
                            }}>
                                {this.state.message}
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                        }}>
                            {/* <Button variant="primary" size="lg" onClick={(e) => {
                                this.onConfirm();
                            }}>
                                确定
                            </Button> */}
                            <div style={{
                                color: "#000",
                                fontSize: "1.2rem",
                            }} onClick={(e) => {
                                this.setState({
                                    showInput: false
                                });

                            }} >Cancel</div>
                            <div style={{
                                color: '#000',
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                            }} onClick={(e) => {
                                this.onConfirm();
                            }}>Add</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function App11Page() {
    const { t, i18n } = useTranslation();
    return (
        <_App11Page t={t} i18n={i18n} />
    );
}

export default App11Page;